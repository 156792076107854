var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"template-airport@undefined"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs';
import { isProductionBuild } from './src/utils/isProductionBuild';

if (isProductionBuild()) {
	Sentry.init({
		dsn: 'https://57c7aff494d1ad5a6e48f9e294cdfac5@o232156.ingest.sentry.io/4506757114298368',
		tracesSampleRate: 0.1,
		debug: false,
		environment: process.env.ENVIRONMENT,
	});
}
